.App {
  background-color: #282c34;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-content {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  color: white;
}

.App-page-title {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center;
}

.App-page-title-text {
  font-size: 30px;
}

.App-list {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center;
  color: white;
}

.App-row {
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  border-style: solid;
  border-color: darkgray;
  max-width: 480px;
}

.App-col {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 4vmin;
}

.App-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
}

.App-desc {
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 5;
  margin-top: 5;
}

.App-link {
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 5;
  margin-top: 5;
  color: #61dafb;
}

.App-link-img {
  width: 50%;
  object-fit: cover;
}

.App-footer{
  margin-top: 1rem;
  padding: 1rem;
  background-color: #001f44;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.App-footer-text {
  font-weight: normal;
  font-size: 16px;
  color:white;
  margin-bottom: 0;
  margin-top: 0;
}


